import React from 'react';
import { useWallcoveringsFilterCritera } from '../useWallcoveringsFilterCriteria';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveHeaderSubMenuSubItem,
  toggleActiveSubmenu,
} from '../../state/ui/uiSlice';
import { useWallcoveringsMenuSections } from './useWallcoveringsMenuSections';
import selectActiveHeaderSubMenuSubItem from '../../state/ui/selectActiveHeaderSubMenuSubItem';
import { Next } from 'grommet-icons';

const StlyedList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.global.edgeSize.small};

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul.sub-menu {
    padding-left: 1rem;
  }
`;

interface WallcoveringsFilterMenuProps {
  splitColumns: boolean;
}

const StyledText = styled(Text)`
  cursor: pointer;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  &.active {
    text-decoration: underline;
  }
`;

function WallcoveringsFilterMenu({
  splitColumns,
}: WallcoveringsFilterMenuProps) {
  const dispatch = useDispatch();
  const sections = useWallcoveringsMenuSections();
  const active = useSelector(selectActiveHeaderSubMenuSubItem);

  const handleCriteriaClick = (id) => {
    dispatch(setActiveHeaderSubMenuSubItem(id));
  };

  return (
    <Box pad={{ vertical: 'large' }}>
      <StlyedList>
        {sections.map(
          ({ title, id, items, visible }) =>
            visible && (
              <li>
                <StyledText
                  className={active === id ? 'active' : ''}
                  onClick={() => handleCriteriaClick(id)}
                >
                  {title} <Next size="10px" />
                </StyledText>
                <ul
                  className="sub-menu"
                  style={{ display: splitColumns ? 'none' : 'block' }}
                >
                  {items.map((item) => (
                    <li key={item.id}>
                      <Link
                        to={`/collections/wallcoverings?filter=${item.slug}`}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            )
        )}
      </StlyedList>
    </Box>
  );
}

export default WallcoveringsFilterMenu;
