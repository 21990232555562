import React from 'react';
import { useSelector } from 'react-redux';
import selectActiveHeaderSubMenuSubItem from '../../state/ui/selectActiveHeaderSubMenuSubItem';
import { useWallcoveringsMenuSections } from './useWallcoveringsMenuSections';
import styled from 'styled-components';
import SmartLink from '../SmartLink';

const StlyedList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.global.edgeSize.small};

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export function ActiveWallcoveringsFilterHeaderMenu({
  clamp,
}: {
  clamp?: number;
}) {
  const activeSubMenuSubItem = useSelector(selectActiveHeaderSubMenuSubItem);
  const sections = useWallcoveringsMenuSections();

  const activeSection = sections.find(({ id }) => id === activeSubMenuSubItem);

  return (
    <div>
      {activeSection && (
        <StlyedList>
          {activeSection.items
            .slice(0, clamp ? clamp : activeSection.items.length)
            .map((item) => (
              <li key={item.id}>
                <SmartLink
                  plain
                  to={`/collections/wallcoverings?filter=${item.slug}`}
                >
                  {item.title}
                </SmartLink>
              </li>
            ))}
          {clamp
            ? activeSection.items.length > clamp && (
                <SmartLink plain to={`/collections/wallcoverings`}>
                  + {activeSection.items.length - clamp} more
                </SmartLink>
              )
            : null}
        </StlyedList>
      )}
    </div>
  );
}
