import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { map, get, head, reject, isNil, find } from '../../lib/nodash';
import gql from 'graphql-tag';

import { CHECKOUT_LINE_ITEMS_ADD } from '../../queries/cartQueries';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import checkLimits from '../../lib/cart/checkLimits';
import encodeGid from '../../lib/encodeGid';
import extractGid from '../../lib/extractGid';
import {
  setErrors,
  setOpen,
  addToSamplesArrangement,
  clearCheckout,
  setCheckoutId,
} from '../../state/cart/cartSlice';
import { setActiveModal } from '../../state/ui/uiSlice';
import { track } from '../../lib/analytics';
import { PRODUCT_ADDED } from '../../lib/analytics/segmentActions';
import { CREATE_CHECKOUT } from '../../queries/cartQueries';
import eventBus from '../../lib/EventBus';
import deNodify from '../../lib/deNodify';
import findImage from '../../lib/findImage';

function useAddToCart() {
  const checkoutId = useSelector((state) => state.cart.checkoutId);
  const dispatch = useDispatch();
  const [createCheckout] = useMutation(CREATE_CHECKOUT, {
    variables: { input: {} },
    client: shopifyClient,
    onCompleted: (d) => {
      dispatch(setCheckoutId(get('checkoutCreate.checkout.id', d)));
    },
  });

  const [addLineItems, { loading, data }] = useMutation(
    CHECKOUT_LINE_ITEMS_ADD,
    {
      client: shopifyClient,
      refetchQueries: ['Checkout'],
      update: (cache, { data }) => {
        const checkout = get('checkoutLineItemsAdd.checkout', data);
        cache.writeFragment({
          id: cache.identify(checkout),
          fragment: gql`
            fragment CheckoutLineItemsUpdateFragment on Checkout {
              lineItems
              lineItemsSubtotalPrice
              subtotalPriceV2
            }
          `,
          data: checkout,
        });
      },
      onError: async (e) => {
        if (e.indexOf('Unable to access Checkout') > -1);
        {
          dispatch(clearCheckout());
          await createCheckout();
        }
        dispatch(
          setErrors([
            {
              title: 'Our bad',
              body: 'There was an issue when adding to your cart. Please try again',
            },
          ])
        );
      },
    }
  );

  const addToCart = async (lineItems, options = {}) => {
    const {
      toChange,
      errors,
      samples = [],
      variantData,
    } = await checkLimits(lineItems, checkoutId, false, {
      inventoryPolicies: options?.inventoryPolicies,
    });

    const isSampleAddition = lineItems.length === 1 && samples.length > 0;
    const apiResponse = await addLineItems({
      variables: {
        lineItems: map(
          (x) => ({
            quantity: x.quantity,
            variantId: encodeGid(x.variantId),
            customAttributes: x.customAttributes,
          }),
          reject((y) => {
            return isNil(y.quantity);
          }, toChange)
        ),
        checkoutId,
      },
    });

    const checkoutUrl = get('data.checkoutLineItemsAdd.checkout.webUrl');

    lineItems.forEach((item) => {
      const variant = find((z) => {
        return z.id === item.variantId;
      }, variantData);
      const images = deNodify(variant.product.images);

      const heroImage = findImage(images, '_hero');
      const collectionImage = findImage(images, '_grid');
      const cartImage = findImage(images, 'cart');
      const productImages = images;

      track(PRODUCT_ADDED, {
        cart_id: checkoutId,
        product_id: extractGid(get('product.id', variant)),
        product_name: get('product.title', variant),
        image_url: collectionImage,
        hero_image: heroImage,
        cart_image: cartImage,
        product_images: productImages,
        sku: get('sku', variant),
        name: get('product.title', variant),
        variant: get('title', variant),
        variant_id: get('id', variant),
        price: parseFloat(get('priceV2.amount', variant)),
        quantity: item.quantity,
        checkout_url: checkoutUrl,
      });
    });

    eventBus.dispatch('cart:add', lineItems);

    if (errors.length > 0) {
      const oos = errors.find((x) => x.type === 'out-of-stock');
      if (oos) {
        return dispatch(
          setActiveModal({
            type: 'component',
            value: 'product-restock-notification',
            meta: oos.meta,
          })
        );
      }
      return dispatch(setErrors(errors));
    }
    if (isSampleAddition) {
      dispatch(addToSamplesArrangement(get('id', head(samples))));
    }
    dispatch(setOpen(true));
  };

  return { addToCart, loading, data };
}

export default useAddToCart;
