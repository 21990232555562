import React, { memo } from 'react';
import PropTypes from 'prop-types';

import HeaderMenu from './HeaderMenu';
import HeaderMenuSection from './HeaderMenuSection';
import HeaderMenuItem from './HeaderMenuItem';
import HeaderSubMenuItem from './HeaderSubMenuItem';
import HeaderSectionMenu from './HeaderSectionMenu';
import HeaderSectionMenuItem from './HeaderSectionMenuItem';
import HeaderMenuPaintByColor from './HeaderMenuPaintByColor';
import WallcoveringsFilterMenu from './Wallcoverings/WallcoveringsFilterMenu';
import useVisibilitySettings from './useVisibilitySettings';
import HeaderMenuWallcoveringsByColor from './HeaderMenuWallcoveringsByColor';

function getComponent(component, rest) {
  switch (component) {
    case 'MenuSection':
      return <HeaderMenuSection {...rest} component={component} />;
    case 'MenuItem':
      return <HeaderMenuItem {...rest} component={component} />;
    case 'SectionMenu':
      return <HeaderSectionMenu {...rest} component={component} />;
    case 'SectionMenuItem':
      return <HeaderSectionMenuItem {...rest} component={component} />;
    case 'SubMenuItem':
      return <HeaderSubMenuItem {...rest} component={component} />;
    case 'SubMenu':
      return (
        <HeaderSubMenuItem text={rest.title} {...rest} component={component} />
      );
    case 'MenuPaintByColor':
      return <HeaderMenuPaintByColor {...rest} component={component} />;
    case 'MenuWallcoveringsbyColor':
      return <HeaderMenuWallcoveringsByColor {...rest} component={component} />;
    case 'WallcoveringsFilterMenu':
      return <WallcoveringsFilterMenu {...rest} component={component} />;
    default:
      return <HeaderMenu {...rest} component={component} />;
  }
}

const HeaderMenuComponent = ({ component, ...rest }) => {
  const isVisible = useVisibilitySettings(rest.visibility || []);
  return isVisible ? getComponent(component, rest) : null;
};

HeaderMenuComponent.propTypes = {
  component: PropTypes.string,
};

export default memo(HeaderMenuComponent);
