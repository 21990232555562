import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  height: auto;
  max-height: 80vh;
  overflow: auto;
  border-top: ${(p) =>
    p.hasBorder ? `${p.theme.global.borderSize.small} solid` : 'none'};
  border-left: none;
  gap: ${(p) => p.theme.global.edgeSize[p.gap]};

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    border-top: none;
    border-left: ${(p) =>
      p.hasBorder ? `${p.theme.global.borderSize.small} solid` : 'none'};
    padding: ${(p) => p.theme.global.edgeSize[p.padSize]};
  }
`;

import HeaderMenuComponent from './HeaderMenuComponent';
import { ActiveWallcoveringsFilterHeaderMenu } from './Wallcoverings/ActiveWallcoveringsFilterHeaderMenu';
import useMobile from './useMobile';
import { useWallcoveringsMenuSections } from './Wallcoverings/useWallcoveringsMenuSections';
import selectActiveHeaderSubMenuSubItem from '../state/ui/selectActiveHeaderSubMenuSubItem';
import { useSelector } from 'react-redux';

const HeaderMenuSection = ({ items = [], index, ...rest }) => {
  const isMobile = useMobile();
  const hasBorder = index > 0;
  const hasImages = items.some((item) => {
    return item?.image?.filename;
  });

  const padSize = hasImages ? 'large' : 'xlarge';
  const hasSplitWallcoveringsMenu =
    items.some(
      (item) =>
        item.component === 'WallcoveringsFilterMenu' && item.splitColumns
    ) && !isMobile;

  const activeSubMenuSubItem = useSelector(selectActiveHeaderSubMenuSubItem);
  const sections = useWallcoveringsMenuSections();

  const activeSection = sections.find(({ id }) => id === activeSubMenuSubItem);

  return (
    <StyledBox
      gap="xsmall"
      hasBorder={hasBorder}
      fill
      padSize={padSize}
      direction={hasSplitWallcoveringsMenu ? 'row' : 'column'}
    >
      <Box flex={true} gap="medium">
        {items.map((item) => {
          const component =
            item.component === 'Menu'
              ? 'SectionMenu'
              : item.component === 'MenuItem'
              ? 'SectionMenuItem'
              : item.component;
          return (
            <Box key={item._uid} direction="row">
              <HeaderMenuComponent
                key={item._uid}
                {...item}
                {...rest}
                component={component}
              />
            </Box>
          );
        })}
      </Box>
      {hasSplitWallcoveringsMenu && activeSection && (
        <Box flex={true}>
          <ActiveWallcoveringsFilterHeaderMenu clamp={11} />
        </Box>
      )}
    </StyledBox>
  );
};

HeaderMenuSection.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  items: PropTypes.array,
  _uid: PropTypes.string,
};

export default memo(HeaderMenuSection);
