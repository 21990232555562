import { graphql, useStaticQuery } from 'gatsby';
import { compact, get, titleCase, uniq } from '../lib/nodash';
import deNodify from '../lib/deNodify';
import { filterWallcoveringsSizeTags, isPeelAndStick } from '../lib/product';
import { WALLCOVERINGS_SIZE_TAGS } from '../lib/constants';
import { useStoryblokState } from '../lib/storyblok';
import { useMemo } from 'react';

const ENABLE_TYPE_FILTERS = false;

export function useWallcoveringsFilterCritera() {
  const data = useStaticQuery(graphql`
    query WallcoveringsFilterData {
      filterCollections: allShopifyCollection(
        filter: {
          metafields: {
            elemMatch: {
              namespace: { eq: "filters" }
              key: { eq: "category" }
              value: { eq: "true" }
            }
          }
        }
      ) {
        edges {
          node {
            title
            handle
            id
            products {
              id
              shopifyId
            }
          }
        }
      }

      colorTagGroup: strapiTagGroup(slug: { eq: "color" }) {
        __typename
        id
        strapiId
        name
        tags {
          __typename
          name
          id
          slug
        }
      }

      filters: storyblokEntry(
        full_slug: { eq: "filters/default-paint-filters" }
      ) {
        content
        id
        slug
        uuid
        internalId
      }

      patterns: allShopifyCollection(
        filter: {
          metafields: {
            elemMatch: { key: { eq: "type" }, value: { eq: "Wallcoverings" } }
          }
        }
        sort: { order: ASC, fields: [handle] }
      ) {
        edges {
          node {
            id
            title
            description
            products {
              designer: metafield(key: "name", namespace: "designer") {
                value
              }
              shopifyId
              id
              designer: metafield(key: "name", namespace: "designer") {
                value
              }
              tags
              variants {
                id
                shopifyId
                title
                selectedOptions {
                  name
                  value
                }
              }
            }
            shopifyId
          }
        }
      }
    }
  `);
  const patterns = deNodify(data.patterns);
  const products = patterns.flatMap((x) => x.products);
  const colors = data.colorTagGroup.tags;

  const designers = uniq(
    compact(products.map((x) => x.designer?.value))
  ).sort();

  const filterCollections = deNodify(data.filterCollections).sort((a, b) =>
    a.title > b.title ? 1 : -1
  );

  const filterStory = useStoryblokState(data.filters);
  const filterGroups = useMemo(
    () => get('content.filterGroups', filterStory) || [],
    [data.filters]
  );

  const colorFilters = useMemo(
    () => filterGroups.find(({ title }) => title === 'Color')?.filters ?? [],
    [filterGroups]
  );

  const sizes = uniq(
    compact(products.map((x) => x.tags).flat()).filter(
      filterWallcoveringsSizeTags
    )
  )
    .sort((a, b) => {
      return (
        WALLCOVERINGS_SIZE_TAGS.indexOf(a) - WALLCOVERINGS_SIZE_TAGS.indexOf(b)
      );
    })
    .map((tag) => ({
      label: titleCase(tag.replace('-', ' ')),
      slug: tag,
    }));

  const hasPeelAndStick = products.some((x) =>
    x.variants.some((v) => isPeelAndStick(v))
  );

  const typeFilters = ENABLE_TYPE_FILTERS
    ? [{ name: 'Traditional', slug: 'traditional' }].concat(
        hasPeelAndStick
          ? [{ name: 'Peel & Stick', slug: 'peel-and-stick' }]
          : []
      )
    : [];

  return {
    designers,
    patterns,
    sizes,
    colors,
    colorFilters,
    filterCollections,
    typeFilters,
  };
}
