import { useSelector } from 'react-redux';
import { useWallcoveringsFilterCritera } from '../useWallcoveringsFilterCriteria';

export function useWallcoveringsMenuSections() {
  const { designers, sizes, filterCollections, typeFilters } =
    useWallcoveringsFilterCritera();

  const activeSubmenus = useSelector((state) => state.ui.activeSubmenus);

  const sections = [
    {
      title: 'Wallcoverings by Style & Pattern',
      id: 'pattern',
      items: filterCollections.map(({ id, handle, ...rest }) => ({
        ...rest,
        id,
        slug: handle,
        active: activeSubmenus[handle],
      })),
      visible: filterCollections.length > 0,
      menuActive: activeSubmenus['pattern'],
    },
    {
      title: 'Wallcoverings by Designer',
      id: 'designer',
      items: designers.map((designer) => ({
        id: designer,
        slug: designer,
        title: designer,
      })),
      visible: designers.length > 0,
      menuActive: activeSubmenus['designer'],
    },
    {
      title: 'Wallcoverings by Application Type',
      id: 'application-type',
      items: typeFilters.map((item) => ({
        id: item.slug,
        slug: item.slug,
        title: item.name,
      })),
      visible: typeFilters.length > 0,
      menuActive: activeSubmenus['application-type'],
    },
    {
      title: 'Wallcoverings by Size',
      id: 'size',
      items: sizes.map((item) => ({
        id: item.slug,
        slug: item.slug,
        title: item.label,
      })),
      visible: sizes.length > 0,
      menuActive: activeSubmenus['size'],
    },
  ];

  return sections;
}
