import { createSelector } from 'reselect';

const selectActiveHeaderSubMenuSubItem = createSelector(
  (state) => state.ui,
  (ui) => {
    return ui.activeHeaderSubMenuSubItem;
  }
);

export default selectActiveHeaderSubMenuSubItem;
